<template>
  <b-form-group>
    <h1>{{input.title}} <i v-if="Object.keys(input.modal).length > 0" class="open-modal fas fa-info-circle" @click="toggleModal()" ></i></h1> 
    <b-form-checkbox-group :name="input.name" stacked :checked="checked">
      <template v-for="option in input.options">
        <b-form-checkbox
            :id="option.id"
            class="checkbox-switch"
            :value="option.id"
            :key="option.id"
            @change="changeInput">{{option.name}}
        </b-form-checkbox>
      </template>
    </b-form-checkbox-group>
  </b-form-group>
</template>

<script>
import { bus } from '@/main'

export default {
    name: 'Checkbox',
    props: ['input', 'checked'],
    data() {
      return {}
    },
    created() {
      bus.$on('validateInput', () => {
        this.validateInput();
      });
    },
    methods: {
        changeInput(value) {
          this.$emit('change', {[this.input.name]: value})
        },
        toggleModal() {
          this.$emit('show-modal', this.input.modal)
        },
        validateInput() {
        }
    }
}
</script>
