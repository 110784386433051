<template>
  <div class="tab">
    <div class="tab-title">
      <h3>{{tab.name}}</h3>
    </div>

    <div class="tab-inputs">
      <div v-for="(input, index) in tab.inputs" v-bind:key="index">
        <template v-if="input.inputType === 'radio'">
          <Radio :input="input" :checked="config_options[input.name]" @change="formatOptions($event)" v-on:show-modal="showModal($event)" v-on:validation="validation($event)"></Radio>
        </template>

        <template v-else-if="input.inputType === 'radio-inline'">
          <RadioInline :input="input" :checked="config_options[input.name]" @change="formatOptions($event)" v-on:show-modal="showModal($event)" v-on:validation="validation($event)"></RadioInline>
        </template>

        <template v-else-if="input.inputType === 'radio-image'">
          <RadioImage :input="input" :checked="config_options[input.name]" @change="formatOptions($event)" v-on:show-modal="showModal($event)" v-on:validation="validation($event)"></RadioImage>
        </template>

        <template v-else-if="input.inputType === 'checkbox-image'">
          <CheckboxImage :input="input" :checked="config_options[input.name]" @change="formatOptions($event)" v-on:show-modal="showModal($event)" v-on:validation="validation($event)"></CheckboxImage>
        </template>

        <template v-else-if="input.inputType === 'radio-color'">
          <RadioColor :input="input" :checked="config_options[input.name]" @change="formatOptions($event)" v-on:show-modal="showModal($event)" v-on:validation="validation($event)"></RadioColor>
        </template>

        <template v-else-if="input.inputType === 'number'">
          <Number :input="input" :valueobj="config_options[input.name]" @change="formatOptions($event)" v-on:show-modal="showModal($event)" v-on:validation="validation($event)"></Number>
        </template>

          <template v-else-if="input.inputType === 'checkbox'">
          <Checkbox :input="input" :valueobj="config_options[input.name]" @change="formatOptions($event)" v-on:show-modal="showModal($event)" v-on:validation="validation($event)"></Checkbox>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Radio from "@/components/Inputs/Radio"
import RadioInline from "@/components/Inputs/RadioInline"
import RadioImage from "@/components/Inputs/RadioImage"
import CheckboxImage from "@/components/Inputs/CheckboxImage"
import RadioColor from "@/components/Inputs/RadioColor"
import Number from "@/components/Inputs/Number"
import Checkbox from "@/components/Inputs/Checkbox"

export default {
    name: 'Tab',
    props: ['tab'],
    data() {
      return {
        tabs: {
          1: 'Deur',
          2: 'Uitvoering en maat',
          3: 'Profilering en kleur'
        },
      }
    },
    methods: {
      updateValues(value_obj) {    
        const new_config_options = Object.assign({}, this.config_options, value_obj)
        let changed_input = Object.keys(value_obj)[0];
        this.$emit('change-input', {new_config_options, changed_input})
      },
      formatOptions(object) {
        this.$emit('changed-input', object)
      },
      showModal(content){
        this.$emit('show-modal', content)
      },
      validation(valid){
        this.$emit('validation', valid)
      }
    },
    computed: {
      config_options() {
        return this.$store.state.config_options
      }
    },
    components: {Radio, RadioInline, RadioImage, CheckboxImage, RadioColor, Number, Checkbox}
}
</script>
