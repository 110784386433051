<template>
  <Unity v-bind="{hideFooter: true}" src="unity/Build/unity.json" unityLoader="unity/Build/UnityLoader.js" ref="UnityInstance"></Unity>
</template>
<script>
  import Unity from 'vue-unity-webgl'
  import { bus } from '@/main'

  export default {
    name: 'UnityView',
    components: { Unity },
    methods: {
      updateUnity() {
        const config_options = this.$store.state.config_options
        config_options.breedte = config_options.dagmaat.breedte;
        config_options.hoogte = config_options.dagmaat.hoogte;
        // console.log(JSON.stringify(config_options))
        this.$refs.UnityInstance.message("Main Object", "WebGLEndpoint", JSON.stringify(config_options))

      },
      animateDoor() {
        this.$refs.UnityInstance.message("Deur", "animationPlay")
      }
    },
    created() {
      bus.$on('changeUnity', (object) => {
        this.updateUnity(object)
      });
      bus.$on('animateDoor', () => {
        this.animateDoor()
      })
    }
  }
</script>