import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        config_options: {
            deurtype: 'loopdeur',
            model: 'paneel',
            dikte: 40,
            dagmaat: {
                breedte: 1000,
                hoogte: 2200,
            },
            uitvoering: 'links_draaiend',
            type: 'transport_hoekje',
            profilering: 'box',
            kleur_omlijsting: 'blank_alu.',
            kleur_paneel: 'hps_wit',
            vergrendeling: 'knopcilinder',
            afsluiting: 'tochtborstels_zijkanten',
            glas: 'geen',
            opties: []
        }
    },
    mutations: {
        updateOptions (state, object) {
            for(const [key, value] of Object.entries(object)){
                state.config_options[key] = value;
            }
        },
        updateStore (state, object) {
            state.config_options = object;
        }
    },
    actions: {
        updateOptions ({commit}, object) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    commit('updateOptions', object);
                resolve();
                });
            });               
        },
        updateStore ({commit}, object) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    commit('updateStore', object);
                resolve();
                });
            });
        }
    }
});
