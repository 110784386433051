<template>
  <b-form-group>
    <h1>{{input.title}} <i v-if="Object.keys(input.modal).length > 0" class="open-modal fas fa-info-circle" @click="toggleModal()" ></i></h1> 
    <b-form-checkbox-group :name="input.name" :checked="checked">
      <template v-for="(option, index) in input.options">
        <b-form-checkbox
            :id="option.id"
            class="checkbox-image"
            :value="option.id"
            :key="index"
            @change="changeInput">
          <img :src="getImage(option.image)">
        </b-form-checkbox>
      </template>
    </b-form-checkbox-group>
  </b-form-group>
</template>

<script>
export default {
    name: 'CheckboxImage',
    props: ['input', 'checked'],
    data() {
      return {}
    },
    methods: {
      changeInput(value) {
        this.$emit('change', {[this.input.name]: value})
      },
      toggleModal() {
        this.$emit('show-modal', this.input.modal)
      },
      getImage(url) {
          let defaultImage = '@/assets/images/logo.png' // just set default
          let path = '@/assets/images/configurator/' + url
          try {
            return require('@/assets/images/configurator/' + url)
          } catch (e) {
            return defaultImage
          }
      
      }
    }
}
</script>
