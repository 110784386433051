<template>
  <div class="tab">
    <div class="tab-title">
      <h3>Verstuur uw ontwerp en ontvang per email uw persoonlijke aanvraag met alle bijbehorende gegevens.</h3>
    </div>
    <div class="tab-inputs">
      <h1>Uw gegevens</h1>
      <div class="form-group text">
        <b-form-input id="name" :required="true" :state="states.name" type="text" v-model="user['name']" placeholder="Naam" v-on:keyup="changeInput"></b-form-input>
      </div>

      <div class="form-group text">
        <b-form-input id="address" :required="true" :state="states.address" type="text" v-model="user['address']" placeholder="Adres" v-on:keyup="changeInput"></b-form-input>
      </div>
      
      <div class="form-group text">
        <b-form-input id="city" :required="true" :state="states.city" type="text" v-model="user['city']" placeholder="Woonplaats" v-on:keyup="changeInput"></b-form-input>
        
      </div>

      <div class="form-group text">
        <b-form-input id="phone" :required="true" :state="states.phone" type="tel" v-model="user['phone']" placeholder="Telefoonnummer" v-on:keyup="changeInput"></b-form-input>
      </div>

      <div class="form-group text">
        <b-form-input id="email" :required="true" :state="states.email" type="email" v-model="user['email']" placeholder="E-mail" v-on:keyup="changeInput"></b-form-input>
      </div>
      
      <div class="form-group text">
        <b-form-textarea
          id="note"
          v-model="user.note"
          placeholder="Opmerkingen/vragen"
          rows="3"
          max-rows="6"
          v-on:keyup="changeInput"
        ></b-form-textarea>
      </div>
    </div>    
    <div class="tab-inputs">
      <div class="form-group checkbox-switch">
        <h1>Extra services</h1>
        <b-form-checkbox id="extra_1" v-model="user['extra_1']" class="checkbox-switch">
          Inmeetservice op locatie
        </b-form-checkbox>
        <b-form-checkbox id="extra_2" v-model="user['extra_2']" class="checkbox-switch">
          Montage door Beek Deuren BV
        </b-form-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from '@/main'

export default {
  name: 'FinalTab',
  data() {
    return {
      user: {
        name: '',
        address: '',
        city: '',
        phone: '',
        email: '',
        note: '',
        extra_1: '',
        extra_2: ''
      },
      states: {
        name: null,
        address: null,
        city: null,
        phone: null,
        email: null
      }
    }
  },
  methods: {
    updateUserStates(invalidInputs) {
      for(const state in this.states) {
        if(invalidInputs.includes(state)) {
          this.states[state] = false
        } else {
          this.states[state] = null
        }
      }
    },
    changeInput () {
      this.$emit('userDataUpdated', this.user)
    }
  },
  created() {
    this.changeInput(),
    bus.$on('updateUserStates', (invalidInputs) => {
      this.updateUserStates(invalidInputs);
    });
  }
}
</script>