<template>
  <b-form-group>
    <h1>{{input.title}} <i v-if="Object.keys(input.modal).length > 0" class="open-modal fas fa-info-circle" @click="toggleModal()" ></i></h1> 
    <b-form-radio-group :name="input.name" :checked="checked">
      <template v-for="option in input.options">
        <b-form-radio
          :id="input.name + '_' + option.id"
          class="radio-color"
          :value="option.id"
          :key="option.id"
          :data-color="option.color"
          :data-font-color="option.font_color"
          @change="changeInput"
        >{{option.name}}
        </b-form-radio>
      </template>
    </b-form-radio-group>
  </b-form-group>
</template>

<script>
import { bus } from '@/main'

export default {
    name: 'RadioColor',
    props: ['input', 'checked'],
    data() {
      return {}
    },
    methods: {
      changeInput(value) {
        this.$emit('change', {[this.input.name]: value})
      },
      toggleModal() {
        this.$emit('show-modal', this.input.modal)
      },
      setColors() {
        $(".radio-color").each(function(index, input) {
          let color = $(input).find('.custom-control-input').data('color')
          $(input).find('.custom-control-label').css('background-color', color)
          let font_color = $(input).find('.custom-control-input').data('fontColor')
          $(input).find('.custom-control-label').css('color', font_color)
        })
      }
    },
    mounted() {},
    created() {
      bus.$on('setColors', () => {
        this.setColors();
      });
    }
}
</script>
