import Vue from 'vue';
import VueRouter from 'vue-router'
import store from "./utils/store";
import axios from './utils/axios';
import App from "./App";
import ScreenshotModel from "@/components/ScreenshotModel";
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/scss/solid.scss';
import './libs/font-awesome';
import './assets/scss/global.scss';
import './assets/scss/inputs.scss';
import './assets/scss/modal.scss';
import jQuery from 'jquery';

Vue.use(BootstrapVue)
Vue.use(VueRouter)

window.jQuery = jQuery;
window.$ = jQuery;

export const bus = new Vue();

const routes = [{
  path: '/', name: 'App', component: App
}, {
  path: '/model', name: 'Model', component: ScreenshotModel
}];

const router = new VueRouter({
  mode: 'history',
  routes
})

/* eslint-disable no-new */
new Vue({
  router, axios, store
}).$mount('#app');
