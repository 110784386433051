<template>
    <div class="primary-content" :class="modal.layout">
        <template v-for="(cont, index) in modal.content">
            <b-row v-bind:key="index">
                <b-col>
                    <h2 v-if="cont.Title">{{cont.Title}}</h2>
                    <template v-if="cont.Image" >
                      <img :src="getImage(cont.Image)" alt="">
                    </template>
                    <b-row v-else>
                    <template v-for="(image, img_index) in cont.Images">
                        <b-col md="auto" v-bind:key="img_index">
                            <h3 v-if="image.Title">{{image.Title}}</h3>
                            <img class="multiple" :src="getImage(image.Image)" alt="">
                            <h4 v-if="image.Subtitle">{{image.Subtitle}}</h4>
                        </b-col>
                    </template>
                </b-row>
                </b-col>
            </b-row>
        </template>
    </div>
</template>

<script>
export default {
    name: 'Rows',
    props: ['modal'],
    data() {
      return {
      }
    },
    methods: {
        getImage(url) {
          let defaultImage = '@/assets/images/logo.png' // just set default
          let path = '@/assets/images/modal/' + url
          try {
            return require('@/assets/images/modal/' + url)
          } catch (e) {
            return defaultImage
          }
      
      }
    }
}
</script>

