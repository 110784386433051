<template>
    <div id="app">
        <main class="container-fluid">
            <SideBar v-on:update-store="updateStore($event)"></SideBar>
            <button id="close-sidebar" v-on:click="toggleSidebar()"><i class="fas fa-arrow-left"></i></button>
            <section id="main-content">
                <div class="contact-block hide-mobile">
                    <img src="@/assets/images/contact-bg.png">
                    <p>Direct contact: <a href="tel:0318701047">0318 701 047</a></p>
                </div>
                <h1 class="page-title">Stel uw <span class="light-blue">uitzonderlijk degelijke</span> deur hier samen</h1>
                <UnityModel v-if="420 === 420"></UnityModel>
                <div class="animate-wrapper hide-mobile"><span v-on:click="animateDoor()" class="animate-button">Animeer deur</span></div>
                <!-- <div class="back-url-wrapper hide-mobile"><a class="back-url" href="">terug naar beekdeuren.nl</a></div> -->
                <a href="https://beekdeuren.nl/"><img class="logo-bottom-right hide-mobile" src="@/assets/images/logo.png" alt="Logo"></a>
            </section>
        </main>
    </div>
</template>
<script>
    import SideBar from "@/components/SideBar"
    import UnityModel from "@/components/Unity"
    import options from "@/assets/js/doors.json"
    import { bus } from '@/main'

    export default {
        name: 'app',
        data () {
            return {
            }
        },
        methods: {
            toggleSidebar() {
               $('#sidebar').toggleClass('closed');
               $('#close-sidebar').toggleClass('closed');
               $('#main-content').toggleClass('full');
               $('.webgl-content').toggleClass('full');
            },
            updateStore(array) {
                this.$store.dispatch('updateStore', array[0])
                .then(() => {
                    bus.$emit('changeUnity', array[1])
                })
            },
            animateDoor() {
                bus.$emit('animateDoor')
            }
        },
        components: {
            SideBar,
            UnityModel
        }
    }
</script>
