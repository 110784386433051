<template>
  <Unity v-bind="{hideFooter: true}" src="unity/Build/unity.json" unityLoader="unity/Build/UnityLoader.js"
         ref="UnityInstance"></Unity>
</template>

<script>
import Unity from "vue-unity-webgl";

export default {
  name: "ScreenshotModel",
  components: {Unity},
  async mounted() {
    const params = this.$route.query;
    const config_options = params;
    config_options.hoogte = parseInt(params.hoogte);
    config_options.breedte = parseInt(params.breedte);
    config_options.dikte = parseInt(params.dikte);
    const self = this
    var checkExist = setInterval(function() {
      if ($('ins').length) {
        self.$refs.UnityInstance.message("Main Object", "WebGLEndpoint", JSON.stringify(config_options))
        clearInterval(checkExist);
      }
    }, 100); // check every 100ms/
  }
}
</script>

<style scoped>

</style>