<template>
    <b-modal size="xl" scrollable centered ref="info-modal">
      <div class="d-block">
        <h1>{{modal.title}}</h1>
        <p class="desc">{{modal.description}}</p>
        <template v-if="modal.layout === 'rows'">
            <Rows :modal="modal"></Rows>
        </template>
        <template v-else-if="modal.layout === 'columns'">
            <Columns :modal="modal"></Columns>
        </template>
        <template v-else-if="modal.layout === 'combined'">
            <Combined :modal="modal"></Combined>
        </template>
      </div>
      <div slot="modal-footer">
          <p>Voor vragen kunt u altijd contact met ons opnemen via:</p>
          <p><a href="tel:0318-701-047">0318 701 047</a> of <a href="mailto:info@beekdeuren.nl">info@beekdeuren.nl</a></p>
      </div>
    </b-modal>
</template>

<script>
import { bus } from "@/main"
import Rows from "@/components/Modals/Rows"
import Columns from "@/components/Modals/Columns"
import Combined from "@/components/Modals/Combined"

export default {
    name: 'Modal',
    data() {
        return {
            modal: {}
        }
    },
    created() {
        bus.$on('showModal', (data) => {
           this.modal = data
           this.showModal();
        });
    },
    methods: {
        showModal() {
            this.$refs['info-modal'].show()
        },
        hideModal() {
            this.$refs['info-modal'].hide()
        }
    },
    components: {Rows, Columns, Combined}
}
</script>