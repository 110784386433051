<template>
  <b-form-group>
    <h1>{{input.title}} <i v-if="Object.keys(input.modal).length > 0" class="open-modal fas fa-info-circle" @click="toggleModal()"></i></h1> 
  
    <template v-for="option in input.options">
      <div class="number" :key="option.id">
        <div class="flex-wrapper">
          <b-form-input
            :id="option.id"
            :name="option.id"
            :value="valueobj[option.id]"
            type="number"
            :min="option.min"
            :max="option.max"
            :step=10
            @change="changeInput(option.id, $event, [option.min, option.max, option.name, option.id])"
          ></b-form-input>
          <label :for="option.id">{{option.name}}</label>
        </div>
        <span class="error" v-if="error[option.id]">{{ error[option.id] }}</span>
      </div>
    </template>
  </b-form-group>
</template>

<script>
import validationMixin from '../mixins/validator';

export default {
  name: 'Number',
    props: ['input', 'valueobj'],
    mixins: [validationMixin],
    data() {
      return {
        error: {
          'breedte': false,
          'hoogte': false,
        },
        invalid: false,
      }
    },
    methods: {
      changeInput(target, value, [min_val, max_val, name, id]) {
        let validate = this.validateField(name, value, {required: true, numeric: true, between: {min: min_val, max: max_val}})
        let valid = validate[0]
        this.$emit('validation', valid)
        if (!valid) {
          this.error[id] = validate[1]
        } else {
          this.error[id] = false
          let input_values = this.valueobj
          input_values[target] = value
          this.$emit('change', {[this.input.name]: input_values})
        }
      },
      toggleModal() {
        this.$emit('show-modal', this.input.modal)
      },
      showErrors() {
        if (this.errors.length > 0) {
          this.invalid = true
        } else {
          this.invalid = false
        }
      }
    },
    components: {}
}
</script>
