const validationMixin = {
    methods: {
        validateField(inputName, value, rules) {
            let error = false

            if ('required' in rules) {
                if (value == '') {
                    error = `${inputName} is een verplicht veld!`
                    return [false, error]
                }
            }
        
            if ('numeric' in rules) {
                if ( isNaN(value) ) {
                    error = `${inputName} moet een getal zijn!`
                    return [false, error]
                }
            }
        
            if ('between' in rules) {
                if (value < rules.between.min || value > rules.between.max) {
                    error = `${inputName} moet tussen de ${rules.between.min} mm en ${rules.between.max} mm zijn!`
                    return [false, error]
                }
            }
        
            return [true]
        }
    },
    data: () => ({
      // add data
    })
}
  
export default validationMixin;