<template>
    <b-row class="primary-content" :class="modal.layout">
        <template v-for="(cont, index) in modal.content">
            <b-col md="auto" v-bind:key="index">
                <div v-if="cont.Legend" class="legend">
                    <h2 v-if="cont.Title">{{cont.Title}}</h2>
                    <template v-for="(point, point_index) in cont.Legend">
                        <p v-bind:key="point_index">{{point}}</p>
                    </template>
                </div>
                <img class="tall" v-if="cont.Image_tall" :src="getImage(cont.Image_tall)" alt="">
                <img class="wide" v-else-if="cont.Image_wide" :src="getImage(cont.Image_wide)" alt="">
            </b-col>
        </template>
    </b-row>
</template>

<script>
export default {
    name: 'Combined',
    props: ['modal'],
    data() {
      return {
      }
    },
    methods: {
        getImage(url) {
          let defaultImage = '@/assets/images/logo.png' // just set default
          let path = '@/assets/images/modal/' + url
          try {
            return require('@/assets/images/modal/' + url)
          } catch (e) {
            return defaultImage
          }
      
      }
    }
}
</script>

