<template>
  <aside id="sidebar">
    <div class="content">
      <b-card no-body>
        <b-tabs pills card v-model="tabIndex" v-on:input="tabChange()">
          <b-tab v-for="(tab, index) in tabs" :title="(index + 1).toString()" v-bind:key="index"
                 v-bind:title-item-class="{active: index <= tabIndex}" :disabled="isLoading">
            <Tab :tab="tab" v-on:changed-input="formatOptions($event)" v-on:show-modal="showModal($event)"
                 v-on:validation="validation($event, tabIndex)" lazy></Tab>
          </b-tab>
          <!-- <b-tab :title="summary.toString()" v-bind:title-item-class="{active: (summary - 1) <= tabIndex}" lazy><SummaryTab :tabs="tabs" v-on:go-to-tab="tabIndex = ($event)"></SummaryTab></b-tab> -->
          <b-tab :title="final.toString()" v-bind:title-item-class="{active: tabIndex === (final - 1)}">
            <FinalTab v-on:userDataUpdated="user=$event"></FinalTab>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
    <div class="footer">
      <div class="errorMessage" v-if="this.errored">
        <p><i class="fas fa-exclamation-circle"></i> Er is iets mis gegaan, probeer het later opnieuw of neem <a
          class="font-weight-bold" href="javascript:void(0)">contact</a> op.</p>
      </div>

      <div class="buttons">
        <button class="btn btn-prev" type="button" v-on:click="prevTab" :disabled="isLoading" v-if="tabIndex !== 0">
          Vorige stap
        </button>
        <button class="btn btn-next" type="button" v-on:click="nextTab"
                :disabled="tabIndex >= this.disabledTab || isLoading">
          <i class="fas fa-circle-notch fa-spin" v-if="isLoading"></i>
          {{ (tabIndex === (final - 1) ? 'Verzenden' : 'Volgende stap') }}

        </button>
      </div>

      <div class="contact-info">
        <p class="m-0">Wilt u een afwijkend product?</p>
        <p class="m-0">Neem dan <a class="font-weight-bold" href="https://beekdeuren.nl/contact/">contact</a> met</p>
        <p class="m-0">ons op voor de mogelijkheden</p>
      </div>
    </div>

    <!--    <Modal></Modal>-->
    <!--    <b-modal id="loading-modal">-->

    <!--    </b-modal>-->

    <Modal></Modal>
    <b-modal id="done-modal" hide-footer>
      <div class="clickable" @click="$bvModal.hide('done-modal')"></div>
      <h1>Aanvraag verzonden!</h1>
      <p class="content">We gaan voor u aan de slag, u krijgt binnen 48 uur een aanbieding op maat toegezonden via de
        mail.</p>
    </b-modal>
  </aside>
</template>

<script>
import Tab from "@/components/Tab"
import FinalTab from "@/components/FinalTab"
import options from "../assets/js/doors.json"
import Modal from '@/components/Modal.vue'
import {bus} from '@/main'

export default {
  name: 'SideBar',
  data() {
    return {
      tabIndex: 0,
      disabledTab: 99,
      colorsTab: 2,
      // summary: 5,
      final: 5,
      tabs: [],
      dependables: ['deurtype', 'model'],
      user: {},
      errored: false,
      isLoading: false
    }
  },
  created() {
    this.formatOptions()

    bus.$on('formatOptions', (object) => {
      this.formatOptions(object);
    });
  },
  methods: {
    tabChange() {
      this.errored = false
      if (this.tabIndex === this.colorsTab) {
        bus.$emit('setColors');
      }
    },
    nextTab() {
      if (this.tabIndex === this.final - 1) {
        this.sendForm();
      } else {
        if (this.tabIndex === this.colorsTab - 1) {
          bus.$emit('setColors');
        }
        this.tabIndex += 1
      }
      bus.$emit('validateInput')
    },
    prevTab() {
      this.tabIndex -= 1
    },
    updateStore(store, object) {
      this.$emit('update-store', [store, object])
    },
    showModal(modal) {
      let cur_modal = {
        'title': modal.title,
        'layout': modal.layout,
        'description': modal.description,
        'content': modal.content
      }

      bus.$emit('showModal', cur_modal);
    },
    sendForm() {
      const self = this
      grecaptcha.ready(function () {
        grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, {action: 'submit'}).then(function (token) {
          const user = self.getUserOptions();
          const configurator = self.getConfiguratorOptions();
          if (user && configurator && !self.isLoading) {
            self.isLoading = true;
            self.$http.post(
              '/submit-form',
              {
                user,
                configurator,
                'g-recaptcha-response': token
              }
            )
              .then(response => {
                self.isLoading = false;
                self.errored = false
                self.$bvModal.show('done-modal')
                }
              )
              .catch(error => {
                self.isLoading = false;
                self.errored = true
                console.error(error.message)
              })
          }
        });
      })
    },
    getUserOptions() {
      const invalidInputs = [];
      for (const inputKey in this.user) {
        const optionalInputs = ["note", "extra_1", "extra_2"];
        if (optionalInputs.includes(inputKey)) {
          continue;
        }
        if (this.user[inputKey] === "") {
          invalidInputs.push(inputKey)
        }
      }
      bus.$emit('updateUserStates', invalidInputs)
      if (invalidInputs.length === 0) {
        return this.user
      }
      return false
    },
    getConfiguratorOptions() {
      const configurator = {}
      this.tabs.forEach(tab => {
        tab.inputs.forEach(input => {
          configurator[input.name] = this.config_options[input.name]
        });
      });
      return configurator
    },
    formatOptions(changed_object) {
      const self = this
      let new_tabs = []
      let current_options = JSON.parse(JSON.stringify(self.$store.state.config_options))
      let object = (typeof changed_object === 'undefined') ? {deurtype: "loopdeur"} : changed_object
      for (const [key, value] of Object.entries(object)) {
        current_options[key] = value
        // When deurtype has changed,
        if (key === "deurtype") {
          // Get all the dagmaat objects,
          for (let dagmaatObject of options[1].Tab_settings[0].Options) {
            for (let dependsOnObject of dagmaatObject.Depends_on) {
              // Format value (dubbele_deur -> Dubbele deur),
              let prettyPrintedValue = (value.charAt(0).toUpperCase() + value.slice(1)).replace('_', ' ');
              // Get dagmaat of current deurtype,
              if (dependsOnObject.Value === prettyPrintedValue) {
                // Set value of dagmaat hoogte and breedte to the default value of current deurtype
                let defaultBreedte = dagmaatObject.Values['Breedte-default']
                let defaultHoogte = dagmaatObject.Values['Hoogte-default']
                current_options.dagmaat.breedte = defaultBreedte
                current_options.dagmaat.hoogte = defaultHoogte
              }
            }
          }
        }
      }
      // Foreach tab
      options.forEach(function (tab, index) {
        let new_inputs = []

        // Foreach input
        tab.Tab_settings.forEach(function (input, inp_index) {
          let new_options = []
          let correct_values = []
          let new_modal = []

          // Foreach option
          input.Options.forEach(function (option, opt_index) {
            // Check depends on and return index of correct option
            if (option.Depends_on.length > 0) {
              let correctDependency = true;
              for (let dependency of option.Depends_on) {
                // OR: [[{}], [{}]]
                if (Array.isArray(dependency)) {
                  correctDependency = false
                  for (let inner_dependency of dependency) {
                    if (inner_dependency.Value.replace(' ', '_').toLowerCase() === current_options[inner_dependency.Name.replace(' ', '_').toLowerCase()]) {
                      correctDependency = true
                    }
                  }
                  if (correctDependency) {
                    correct_values = input.Options[opt_index].Values
                    break
                  }

                  // AND: [{}, {}]
                } else {
                  if (dependency.Value.replace(' ', '_').toLowerCase() !== current_options[dependency.Name.replace(' ', '_').toLowerCase()]) {
                    correctDependency = false
                  }
                }
              }
              if (correctDependency) {
                correct_values = input.Options[opt_index].Values
              }
            } else {
              correct_values = input.Options[0].Values
            }
          })
          // Set default values
          if (!['checkbox-image', 'checkbox'].includes(input.Input_type)) {
            if (Array.isArray(correct_values)) {
              let contains = false
              for (let value of correct_values) {
                let validated_value
                if (typeof value !== 'object') {
                  if (typeof value === 'string') {
                    validated_value = value.replace(' ', '_').toLowerCase()
                  } else {
                    validated_value = value
                  }
                } else {
                  if (typeof value.name === 'string') {
                    validated_value = value.name.replace(' ', '_').toLowerCase()
                  } else {
                    validated_value = value.name
                  }
                }
                if (validated_value === self.config_options[input.Setting_name.replace(' ', '_').toLowerCase()]) {
                  contains = true
                  break
                }
              }
              if (!contains) {
                let key = input.Setting_name.replace(' ', '_').toLowerCase()
                let new_value
                if (typeof correct_values[0] !== 'object') {
                  if (typeof correct_values[0] === 'string') {
                    new_value = correct_values[0].replace(' ', '_').toLowerCase()
                  } else {
                    new_value = correct_values[0]
                  }
                } else {
                  if (typeof correct_values[0].name === 'string') {
                    new_value = correct_values[0].name.replace(' ', '_').toLowerCase()
                  } else {
                    new_value = correct_values[0].name
                  }
                }
                current_options[key] = new_value
              }
            }
          }
          // Check input type
          // Add values to option
          if (input.Input_type === 'radio-image') {
            Object.keys(correct_values).forEach(function (key) {
              let value = correct_values[key]
              let option = {
                'id': (!Number.isInteger(value.name) ? value.name.trim().replace(' ', '_').toLowerCase() : value.name),
                'name': value.name,
                'image': value.image,
              }
              new_options.push(option)
            });
          } else if (input.Input_type === 'checkbox-image') {
            Object.keys(correct_values).forEach(function (key) {
              let value = correct_values[key]
              let option = {
                'id': (!Number.isInteger(value.name) ? value.name.trim().replace(' ', '_').toLowerCase() : value.name),
                'name': value.name,
                'image': value.image,
              }

              new_options.push(option)
            });
          } else if (input.Input_type === 'radio-color') {
            Object.keys(correct_values).forEach(function (key) {
              let value = correct_values[key]
              let option = {
                'id': (!Number.isInteger(value.name) ? value.name.trim().replace(' ', '_').toLowerCase() : value.name),
                'name': value.name,
                'color': value.color,
                'font_color': value.font_color
              }

              new_options.push(option)
            });
          } else if (input.Input_type === 'number') {
            let defaultValue = 0
            let min = 0
            let max = 0
            // Only works for breedte/hoogte, rewrite this if another input 'number' is added
            Object.keys(correct_values).forEach(function (key) {
              if (key.slice(-3) === 'max') {
                max = correct_values[key]
              } else if (key.slice(-3) === 'min') {
                min = correct_values[key]
              } else {
                defaultValue = correct_values[key]
              }

              if (max !== 0 && min !== 0 && defaultValue !== 0) {
                let option = {
                  'id': key.slice(0, -4).replace(' ', '_').toLowerCase(),
                  'name': key.slice(0, -4),
                  'defaultValue': defaultValue,
                  'min': min,
                  'max': max
                }

                new_options.push(option)
                defaultValue = 0
                max = 0
                min = 0
              }
            });
          } else {
            if (Array.isArray(correct_values) && correct_values.length) {
              correct_values.forEach(function (value) {
                new_options.push(
                  {
                    'id': (!Number.isInteger(value) ? value.trim().replace(' ', '_').toLowerCase() : value),
                    'name': value
                  }
                )
              })
            }
          }

          // Foreach Modal
          if (input.Modals.length > 0) {
            input.Modals.forEach(function (mod, mod_index) {

              // Check depends on and return index of correct option
              if (mod.Depends_on.length > 0) {
                for (let dependency of mod.Depends_on) {

                  // Todo: Fix met 2 depends_on
                  if (dependency.Value.replace(' ', '_').toLowerCase() === current_options[dependency.Name.replace(' ', '_').toLowerCase()]) {
                    new_modal = {
                      'title': input.Modals[mod_index].Title,
                      'description': input.Modals[mod_index].Description,
                      'layout': input.Modals[mod_index].Layout,
                      'content': input.Modals[mod_index].Content
                    }
                    break
                  }
                }
              } else {
                new_modal = {
                  'title': input.Modals[0].Title,
                  'description': input.Modals[0].Description,
                  'layout': input.Modals[0].Layout,
                  'content': input.Modals[mod_index].Content
                }
              }

            })
          }

          // Set inputs
          if (new_options.length) {
            new_inputs.push(
              {
                'title': input.Setting_name,
                'name': input.Setting_name.trim().replace(' ', '_').toLowerCase(),
                'inputType': input.Input_type,
                'options': new_options,
                'modal': new_modal
              }
            )
          }
        })

        new_tabs.push(
          {
            'name': tab.Tab_name,
            'inputs': new_inputs
          }
        )
      })
      self.tabs = new_tabs
      self.updateStore(current_options, object)
    },
    validation(valid, tabIndex) {
      if (!valid) {
        this.disabledTab = tabIndex
      } else {
        this.disabledTab = 99
      }
    }
  },
  computed: {
    config_options: {
      get() {
        return this.$store.state.config_options
      }
    }
  },
  components: {
    Tab,
    // SummaryTab, 
    FinalTab,
    Modal
  }
}
</script>
